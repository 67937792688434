.profile-container {
	max-width: 900px;
	margin:0 auto;
}

.youtube-frame {
	width:100%; 
	height: 100%;
	border-style: none;
}


.profile-info {
	height: 350px;
}

.profile-info.pic-info {
	text-align: center;
}


.btn.social {
	font-size: 20px;
	background-color:transparent !important;
	border:1px solid white !important;
	color: white;
}

.profile-pic {
	width:auto;
	height: auto;
	max-width: 250px; 
	max-height:200px;
	margin-top: 10px;
	border-radius: 3px;
	box-shadow: 0px 0px 20px #555555;
}

.songs-space {
	width: 100%; 
	min-height: 120px; 
	margin-top: 2%;
}

#songs {
	list-style: none;
}