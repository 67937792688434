.result-container {
	height: 100%; 
	width: 100%; 
	padding:0px; 
	margin:0px; 
	background-image: url('../../images/stage.jpg');
	background-size: cover;
	text-shadow: 0 1px 3px rgba(0,0,0,.5);
	overflow: hidden !important;
	position:fixed;
	top:1px;
	z-index:-1000;
}

.searchAgain {
	min-height: 10px;
	width: 250px;
	margin:0 auto;
	margin-bottom: 20px;
}


.result-shade {
	width:100%; 
	height:100%; 
	background-color: rgba(13, 25, 70, 0.3);
}

 .result-title, .no-results-title{
	color:white !important;
}

.result-style-container {
	padding-top:20px;
	min-height: 300px;
}

.result-title {
	color: #999999; 
	text-align:center;
}

.result-user-container {
	max-width: 700px; 
	margin:0 auto;
}

.result-user-container .username {
	padding-left:15px;
}

.result-user-container img {
	height:50px; 
	width:50px; 
	border-radius: 50px;
}

.result-user-container .skills {
	float:right; 
	padding-right: 15px; 
	padding-top:15px; 
	color:white;
}

.result-user-container .no-results-title {
	text-align:center;
	margin-top:20px;
}

.list-results {
	background-color:transparent; 
	border:0px none transparent; 
	border-bottom:1px solid white; 
	color:white !important;
	border-radius: 0px !important;
}

button.list-group-item.list-results:hover{
	background-color: rgba(255,255,255, 0.4); 
}

button.list-group-item.list-results:focus{
	background-color: rgba(255,255,255, 0.4); 
}