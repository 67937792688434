body {
    font-family: 'Lato';
}

.fa-btn {
    margin-right: 6px;
}

.centered_text {
	text-align: center;
}

.white {
	color:white !important;
}

.ui.dropdown .item {
	font-size: 13px !important;
}

a:hover {
	text-decoration: none;
}